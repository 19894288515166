<template>
  <div style="background-color: #fff" @click="onPageClick">
    <div  style="background: white; height: auto;  padding: 0 0 30px; width: 100%; box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);">
      <div class="category-title">
        <div>
          <h1 style="font-size: 34px;font-weight: 800;color: #2d3640; margin-bottom: 10px;">
           "{{term}}" için arama sonuçları
          </h1>
        </div>
      </div>
    </div>
    <div style="z-index:99;width: 100%;" :class="{catFilterFixed: $store.state.windowScrollTop > 248 }">
      <div style="z-index:1;display: flex; align-items:center;margin: 0 auto;" class="catFilter">
        <div class="catFilter-container">
          <div style="width: 100%; background-color: #ffffff; display: flex; align-items: center;justify-content: space-between;">
            <div style="display: flex;height: 100%; align-items: center;">

              <div v-if="showHelpBubble" style="position: relative">
                <div class="helpbubble">
                  <div class="help-title"><p>Bir kategori seçin</p> <p class="new">Yeni</p></div>
                  <div style="font-size: 14px; line-height: 1.44; margin-left: 15px; margin-top: 2px">Aramalarını daraltmak ve daha fazla filtre görüntülemek için bir kategori seçin.</div>
                  <div style="display: flex; justify-content: flex-end; margin-right: 15px; font-size: 16px; font-weight: 600;"><p class="gotit" @click="gotIt()">Anladım</p></div>
                </div>
              </div>
              <div v-if="facetCategories && facetCategories.length > 0" class="filter-box">
                <div :class="selectedCategorySlug || selectedCategorySlug ? 'the-box active' : 'the-box'" @click="showFilterBox('cats')">
                  Kategori
                  <div class="icon-container">
                    <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                  </div>
                </div>
                <div v-if="showCategoryFilter" id="facet-cats-filter-box" class="filter-options"  v-click-away="() =>  handleFocusOut('cats')">
                  <div class="filter-options-container"  style="margin-top: 20px;">
                    <div @click="addCategoryFilter({slug:null})" :class="selectedCategorySlug ? 'filter-category-item' : 'filter-category-item selected'" >
                      <p>Tüm Kategoriler</p>
                      <p class="cunts">({{gigsCount}})</p>
                    </div>
                    <div @click="addCategoryFilter(cat)" v-for="cat in facetCategories.filter(({ count }) => count > 0)" :class="selectedCategorySlug !== cat.slug ? 'filter-category-item' : 'filter-category-item selected'">
                      <p>{{cat.name}}</p>
                      <p class="cunts">({{cat.count}})</p>
                    </div>

                  </div>
                </div>
              </div>

              <div v-if="categoryTypes && categoryTypes.length > 0" class="filter-box">
                <div :class="selectedType || selectedType ? 'the-box active' : 'the-box'" @click="showFilterBox('types')">
                  Hizmet Türü
                  <div class="icon-container">
                    <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                  </div>
                </div>
                <div v-if="showTypesFilter" id="types-filter-box" class="filter-options"  v-click-away="() =>  handleFocusOut('types')">
                  <div class="filter-options-container"  style="margin-top: 20px;">
                    <div @click="removeFilter('type')" :class="selectedType ? 'filter-category-item' : 'filter-category-item selected'" >
                      <p>Tüm Hizmet Türleri</p>
                      <p class="cunts">({{gigsCount}})</p>
                    </div>
                    <div @click="addTypeFilter(service_type)" v-for="service_type in categoryTypes.filter(({ count }) => count > 0)" :class="selectedType !== service_type.id ? 'filter-category-item' : 'filter-category-item selected'">
                      <p>{{service_type.name}}</p>
                      <p class="cunts">({{service_type.count}})</p>
                    </div>

                  </div>
                </div>
              </div>

              <div v-if="showAllServiceOptions" class="filter-box">
                <div :class="computedSelectedAnyOptions ? 'the-box active' : 'the-box'" @click="showFilterBox('options')">
                  Hizmet Seçenekleri
                  <div class="icon-container">
                    <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                  </div>
                </div>
                <div v-if="showOptionsFilter" id="option-filter-box" class="filter-options"  v-click-away="() =>  handleFocusOut('options')">
                  <div class="long-options" v-if="reRender">

                    <template v-if="serviceMetaOptions.length > 0">
                      <div class="filter-options-container" v-for="(metaOption, moi) in serviceMetaOptions" :key="moi">
                        <p class="filter-options-container-title">{{metaOption.title_for_buyer}}</p>
                        <div style="padding: 25px 0 0;display: flex;  flex-wrap: wrap;align-content: flex-start;">
                          <div v-if="moii < metaOption.sliceCount" v-for="(singleOption,moii) in metaOption.options"  class="filter-checkbox-item" @click="addMetaOptions(moi, moii)">
                            <custom-check
                              :container-style="'width:20px; height:20px;'"
                              :check-mark-style="'width:20px; height:20px; border-radius:4px; border: solid 1px #8b95a1;'"
                              called-from=""
                              :single-gig="{}"
                              :id="singleOption.id + '__' + singleOption.title"
                              :checked="singleOption.selected"
                              :value="singleOption.selected"
                              @input="addMetaOptions(moi, moii)"
                            />
                            <div :class="singleOption.selected ? 'filter-checkbox-label-active' : 'filter-checkbox-label'">{{singleOption.title_for_buyer}}</div>
                            <div v-if="singleOption.count" style="font-weight: 500; font-size: 14px; color: #b8bdc5; margin-left: 5px; margin-right: 10px;">({{singleOption.count}})</div>
                          </div>
                        </div>
                        <div v-if="metaOption.options.length > 4" style="margin-bottom: 30px; margin-top: 8px;">
                          <p v-if="metaOption.options.length > 4 && metaOption.sliceCount === 4" @click="metaOption.sliceCount = 999; reRender = false; reRender = true;" class="show-more">+{{metaOption.options.length - 4}} daha</p>
                          <p v-if="metaOption.options.length > 4 && metaOption.sliceCount !== 4" @click="metaOption.sliceCount = 4; reRender = false; reRender = true;" class="show-more">Daha az göster</p>

                        </div>
                      </div>
                    </template>
                    <template v-if="serviceIncludes.length > 0">
                      <div class="filter-options-container">
                        <p class="filter-options-container-title">Dahil Olan Hizmetler</p>
                        <div style="padding: 25px 0 0;display: flex;  flex-wrap: wrap;align-content: flex-start;">
                          <div @click="addOptions(service)" v-for="service in computedOptions.slice(0, optionsSliceCount)"  class="filter-checkbox-item" >
                            <custom-check
                              :container-style="'width:20px; height:20px;'"
                              :check-mark-style="'width:20px; height:20px; border-radius:4px; border: solid 1px #8b95a1;'"
                              called-from=""
                              :single-gig="{}"
                              :id="service.id + '__' + service.title"
                              :checked="service.selected"
                              :value="service.selected"
                              @input="addOptions(service)"
                            />
                            <div :class="service.selected ? 'filter-checkbox-label-active' : 'filter-checkbox-label'">{{service.title}}</div>
                            <div v-if="service.count" style="font-weight: 500; font-size: 14px; color: #b8bdc5; margin-left: 5px; margin-right: 10px;">({{service.count}})</div>
                          </div>
                        </div>
                        <div style="margin-top: 8px;">
                          <p v-if="computedOptions.length > 6 && optionsSliceCount === 6" @click="optionsSliceCount = 999" class="show-more">+{{computedOptions.length - 6}} daha</p>
                          <p v-if="computedOptions.length > 6 && optionsSliceCount !== 6" @click="optionsSliceCount = 6" class="show-more">Daha az göster</p>
                        </div>
                      </div>
                    </template>

                  </div>
                  <div style="border-top:1px solid #e0e1e1;">
                    <div class="filter-options-container" style="margin-top:10px;display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;">
                      <p @click="removeFilter('option')" class="temizle">Hepsini Temizle</p>
                      <p @click="addOptionFilter" class="super-button uygula">Uygula</p>
                    </div>
                  </div>

                </div>
              </div>
              <div class="filter-box">
                <div :class="minBudget || maxBudget ? 'the-box active' : 'the-box'" @click="showFilterBox('budget')">
                  Bütçe
                  <div class="icon-container">
                    <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                  </div>
                </div>
                <div v-if="showBudgetFilter" id="budget-filter-box" class="filter-options"  v-click-away="() =>  handleFocusOut('budget')" >
                  <div class="filter-options-container" style="margin-bottom: 40px;">
                    <div style="display: flex; margin-top: 20px">
                      <div>
                        <p style="margin-bottom: 10px; font-size: 14px; font-weight: 500;">Min. Fiyat</p>
                        <input autocomplete="off" v-model="minBudgetModel"  id="input_one"  placeholder="hepsi (TL)" style="width: 95px;"   >
                      </div>
                      <div style="margin-left: 25px;">
                        <p style="margin-bottom: 10px; font-size: 14px; font-weight: 500;">Maks. Fiyat</p>
                        <input  autocomplete="off" v-model="maxBudgetModel"  id="input_two" placeholder="hepsi (TL)" style="width: 95px; " >
                      </div>
                    </div>
                  </div>
                  <div style="border-top:1px solid #e0e1e1;">
                    <div  class="filter-options-container" style="display: flex; align-items: center; justify-content: space-between; margin-top: 10px; margin-bottom: 10px;">
                      <p @click="removeFilter('budget')" class="temizle">Temizle</p>
                      <p @click="addBudgetFilter" class="super-button uygula">Uygula</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="filter-box">
                <div :class="selectedDelivery ? 'the-box active' : 'the-box'" @click="showFilterBox('delivery')">
                  Teslim Süresi
                  <div class="icon-container">
                    <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                  </div>
                </div>
                <div v-if="showDeliveryFilter" id="delivery-filter-box" class="filter-options"  tabindex="0" @focusout="handleFocusOut('delivery')">
                  <div class="filter-options-container"  style="margin-top: 20px;">
                    <div @click="changeDeliveryFilter(1)" class="filter-radio-item">
                      <div :class="selectedDelivery !== 1 ? 'filter-radio-empty' : 'filter-radio-full'"></div>
                      <p :class="selectedDelivery !== 1 ? 'filter-radio-label' : 'filter-radio-label-active' ">24 saat içinde</p>
                    </div>
                    <div @click="changeDeliveryFilter(3)" class="filter-radio-item">
                      <div :class="selectedDelivery !== 3 ? 'filter-radio-empty' : 'filter-radio-full'"></div>
                      <p :class="selectedDelivery !== 3 ? 'filter-radio-label' : 'filter-radio-label-active' ">3 gün içinde</p>
                    </div>
                    <div @click="changeDeliveryFilter(7)" class="filter-radio-item">
                      <div :class="selectedDelivery !== 7 ? 'filter-radio-empty' : 'filter-radio-full'"></div>
                      <p :class="selectedDelivery !== 7 ? 'filter-radio-label' : 'filter-radio-label-active' ">7 gün içinde</p>
                    </div>
                    <div @click="changeDeliveryFilter(null)" class="filter-radio-item">
                      <div :class="selectedDelivery ? 'filter-radio-empty' : 'filter-radio-full'"></div>
                      <p :class="selectedDelivery ? 'filter-radio-label' : 'filter-radio-label-active' ">Herhangi bir zaman</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; font-size: 16px;  color: #2d3640; align-items: center">
              Çevrimiçi freelancer’ların ilanlarını göster
              <custom-switch style="margin-left: 10px;"  v-model="showOnlineOnly"></custom-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #f4f5f7; padding-bottom: 50px; min-width: 1170px;">
      <div class="gigContainer" >
        <div>
          <div v-show="reRender && filterLabels && filterLabels.length > 0" class="filter-labels">
            <div v-for="filterLabel in filterLabels" class="filter-label">
              {{ filterLabel.title }}
              <p  @click="removeFilter(filterLabel)" class="close-icon-container">
                <img class="close-icon" src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)"/>
              </p>
            </div>
          </div>
          <div v-if="gigs.length" style="display: flex; align-items: flex-end; justify-content: space-between">
            <div>
              <p v-if="allGigsCount" style="font-weight: 500">{{allGigsCount | number_format}} İş ilanı bulundu</p>
            </div>
            <div class="select-type-container">
              <p style="color: #2D3640; font-weight: 300; font-size: 16px;">Sıralama</p>

              <select @change="orderChange" v-model="orderBy" class="super-drop-tek-cizgi-cats">
                <option :value="1">İlgili Sonuçlar</option>
                <option :value="2">Çok Satanlar</option>
                <option :value="3">Yeni Gelenler</option>
              </select>
            </div>
          </div>
          <div v-if="!pageLoaded" style="margin-left: auto; margin-right: auto;">
            <div style="display: flex;flex-wrap: wrap; margin-top: 30px">
              <div v-for="x in 15" class="card-container">
                <div class="card-image-container card">
                </div>
                <div class="card-detail">
                  <div class="card-detail-container">
                    <div class="card-detail-header-large card">

                    </div>
                    <div class="card-detail-header-small card">

                    </div>
                  </div>
                  <div class="card-detail-user-container ">
                    <div class="card-detail-user">
                      <div class="card-detail-user-image card"></div>
                      <div class="card-detail-user-name card"></div>
                    </div>
                    <div class="card-detail-currency card">
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
                <div class="card-detail-seperator card">

                </div>
                <div class="card-like-currency">
                  <div class="card-like-icon card"></div>
                  <div class="card-currency-icon card"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else >
            <div class="gig-list-container">
              <single-gig :impression-u-u-i-d="impressionUUID" :type="'fromList'" :filters="computedAllFilters" :item="gig" v-for="(gig, gin) in gigs" :key="gin" class="gig-list-gig"></single-gig>

              <div v-if="!gigs.length && pageLoaded" style="width: 1170px; margin: 0 auto; text-align: center; padding-bottom: 100px;">
                <div class="notodoitems content-white" style=" margin-top:0!important;margin-left: 200px; width:770px;text-align: center;">
                  <p v-if="filterLabels.length > 0" style="color: #2cad87; font-size: 15px; font-weight: 500; margin-bottom: 15px; cursor: pointer;" @click="resetAllSettings('getDeeds')">Tüm Filtreleri Kaldır</p>
                  <h4>Sonuç bulunamadı.</h4>
                  <div class="hiddendiv"></div>
                  <span style="line-height: 60px;width: 100px; height: 100px; padding-top: 20px;">
                <img style="width: 75px; height: 75px;" src="https://gcdn.bionluk.com/site/general/no-foun-gig.png">
              </span>
                  <p class="h1_2" style=" width: 400px; margin: 0 auto; font-size:14px; line-height: 1.3; font-weight:400!important;color: #5e6b79!important;">
                    Hayalindeki hizmeti için aradığın ilanı veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!

                  </p>
                  <div class="hiddendiv"></div>

                  <router-link to="/panel/istekler/">
                    <button class="super-button">Alıcı İsteği Oluştur</button>
                  </router-link>

                  <div class="hiddendiv10"></div>
                </div>
              </div>

            </div>
          </div>
          <div class="pagination-bar">
            <paginate v-if="pageLoaded && gigs.length && totalCount > 1"
                      v-model="pageNumber"
                      :page-count="totalCount"
                      :page-range="5"
                      :margin-pages="1"
                      :click-handler="(pageNum) => clickPagination(pageNum)"
                      :prev-text="'<'"
                      :next-text="'>'"
                      :containerClass="'pagination'">
            </paginate>
          </div>
          <div class="hiddendiv"></div>
          <div class="go-request" v-if="pageLoaded">
            <div class="left">
              <p class="title">Hayalindeki <span>"{{term}}"</span> hizmeti için aradığın ilanı veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!</p>
              <button @click="$router.push('/panel/istekler')" class="cuper-red-button" style="margin-top: 20px">Alıcı İsteği Oluştur</button>
            </div>
            <img class="right" src="https://gcdn.bionluk.com/site/cicons/il-special-requests.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import singleGig from "@/pages/components/singleGig"
import CustomSwitch from "@/pages/components/CustomSwitch"
import CustomCheck from "@/pages/components/CustomCheck"
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'

export default {
  name: "src-pages-body-search-v3",
  components: {
    Paginate, singleGig, CustomSwitch, CustomCheck
  },
  data() {
    return {
      showOnlineOnly: null,
      impressionUUID: null,
      showDeliveryFilter: false,
      selectedDelivery: null,
      deliveryLabels: [
        { id: 1, title: '24 saatte teslim' },
        { id: 3, title: '3 günde teslim' },
        { id: 7, title: '7 günde teslim' }
      ],
      showBudgetFilter: false,
      minBudget: null,
      maxBudget: null,
      minBudgetModel: null,
      maxBudgetModel: null,
      showOptionsFilter: false,
      selectedOptions: [],
      optionsSliceCount: 6,
      selectedMetaOptions: [],
      showTypesFilter: false,
      selectedType: null,
      mounted: false,
      offset: 0,
      limit: 32,
      pageLoaded: false,
      totalCount: null,
      gigs: [],
      categorySelect: [],
      selectedCategorySlug: null,
      selectedCategory: { id: null, parent_id: 0 },
      slug: null,
      duration: null,
      orderBy: 1,
      categoryTypes: [],
      serviceIncludes: [],
      serviceMetaOptions: [],
      categoryId: null,
      pageNumber: 1,
      gigsCount: 0,
      allGigsCount: 0,
      reRender: true,
      term: '',
      facetCategories: [],
      showCategoryFilter: false,
      showHelpBubble: false
    }
  },
  methods: {
    gotIt() {
      window.localStorage.setItem("show_search_help_buble", 'no')
      this.showHelpBubble = false;
    },
    addMetaOptions(mi, moi) {
      if(this.serviceMetaOptions[mi]?.options[moi]){
        if(this.serviceMetaOptions[mi]?.info?.type === 'selectbox'){
          if(this.serviceMetaOptions[mi].options[moi].selected){
            this.serviceMetaOptions[mi].options[moi].selected = false;
          } else {
            this.serviceMetaOptions[mi].options.forEach((option, oii) => {
              if(oii === moi){
                option.selected = true;
              } else if(option.selected) {
                option.selected = false;
              }
            });
          }
        } else {
          this.serviceMetaOptions[mi].options[moi].selected = !this.serviceMetaOptions[mi].options[moi].selected;
        }
        this.reRender = false;
        this.reRender = true;
      }
    },
    addOptions(service) {
      let foundIndex = this.serviceIncludes.findIndex(serviceInclude => serviceInclude.id === service.id)
      if(foundIndex > -1){
        let arr = this.serviceIncludes;
        arr[foundIndex].selected = !this.serviceIncludes[foundIndex].selected;
        this.serviceIncludes = arr;
      }
    },
    addOptionFilter() {
      if (this.serviceIncludes.length || this.serviceMetaOptions.length) {
        let tempOptions = this.serviceIncludes
          .filter(service => service.selected)
          .map(service => service.id)

        let kOptions = tempOptions.sort()

        let tempMetaOptions = []
        this.serviceMetaOptions.forEach(metaOption => {
          metaOption.options.forEach(option => {
            if (option.selected) {
              tempMetaOptions.push(option.id)
            }
          })
        })
        let kMetaOptions = tempMetaOptions.sort()

        let hasOptionsChanged = JSON.stringify(this.selectedOptions) !== JSON.stringify(kOptions)
        let hasMetaOptionsChanged =  JSON.stringify(this.selectedMetaOptions) !== JSON.stringify(kMetaOptions)

        if (hasOptionsChanged || hasMetaOptionsChanged) {
          this.selectedOptions = kOptions
          this.selectedMetaOptions = kMetaOptions
          let q_init = this.route.query;
          let qqq = this.addQueryForCategories('/search', 'service_options', this.selectedOptions, true, true, q_init)
          this.addQueryForCategories('/search', 'meta_options', this.selectedMetaOptions, false, false, qqq)
          this.pageNumber = 1
          this.getDeeds(this.pageNumber)
        }
        this.showOptionsFilter = false;
      }
    },
    format_tl(number, decimals = 0, decPoint = ',', thousandsSep = '.') {

      number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
      var n = !isFinite(+number) ? 0 : +number
      var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
      var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
      var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
      var s = ''

      var toFixedFix = function (n, prec) {
        if (('' + n).indexOf('e') === -1) {
          return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
        } else {
          var arr = ('' + n).split('e')
          var sig = ''
          if (+arr[1] + prec > 0) {
            sig = '+'
          }
          return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
        }
      }

      // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
      }

      return s.join(dec)
    },
    addTypeFilter(service_type) {
      if(service_type.id !== this.selectedType){
        this.selectedType = service_type.id;
        this.addQueryForCategories('/search', 'service_type', service_type.id, true);
        this.pageNumber = 1;
        this.getDeeds(this.pageNumber, false, 'service_type');
      }
      this.showTypesFilter = false;

    },
    addCategoryFilter(cat) {
      this.showHelpBubble = false;
      if(cat.slug){
        let url = encodeURI(`/search?term=${encodeURIComponent(this.term)}&slug=${cat.slug}`)
        this.$router.push(url);
        this.showCategoryFilter = false;
      } else {
        let url = encodeURI(`/search?term=${encodeURIComponent(this.term)}`)
        this.$router.push(url);
        this.showCategoryFilter = false;
      }

    },
    addBudgetFilter() {
      let minBudgetInitial = this.minBudget;
      let maxBudgetInitial = this.maxBudget;
      if(isNaN(this.minBudgetModel)){
        this.minBudgetModel = null;
        this.minBudget = null;
      } else {
        this.minBudget = parseInt(this.minBudgetModel);
      }
      if(isNaN(this.maxBudgetModel)){
        this.maxBudgetModel = null;
        this.maxBudget = null;
      } else {
        this.maxBudget = parseInt(this.maxBudgetModel);
      }

      if(this.minBudget && this.maxBudget && this.minBudget > this.maxBudget){
        let max = this.minBudget;
        let min = this.maxBudget;
        this.maxBudget = max;
        this.minBudget = min;
      }

      if(this.minBudget < 1){
        this.minBudget = null;
      }
      if(this.maxBudget < 1){
        this.maxBudget = null;
      }
      if(this.maxBudget > 99999){
        this.maxBudget = 99999;
      }
      if(this.minBudget > 99999){
        this.minBudget = 99999;
      }


      if(this.minBudget !== minBudgetInitial || this.maxBudget !== maxBudgetInitial){
        let q_init = this.route.query;
        let qqq = this.addQueryForCategories('/search', 'minBudget', this.minBudget, true, true, q_init);
        this.addQueryForCategories('/search', 'maxBudget', this.maxBudget, true, false, qqq);
        this.pageNumber = 1;
        this.getDeeds(this.pageNumber);
      }
      this.showBudgetFilter = false;

    },
    removeFilter(filter) {
      if (filter && filter.type === 'delivery') {
        this.selectedDelivery = null;
        this.addQueryForCategories('/search', 'delivery', null, true);
        this.pageNumber = 1;
        this.getDeeds(this.pageNumber);
      }
      if (filter === 'budget' || filter.type === 'budget') {
        this.showBudgetFilter = null;
        this.maxBudgetModel = null;
        this.maxBudget = null;
        this.minBudgetModel = null;
        this.minBudget = null;
        let q_init = this.route.query;
        let qqq = this.addQueryForCategories('/search', 'minBudget', null, true, true, q_init);
        this.addQueryForCategories('/search', 'maxBudget', null, true, false, qqq);
        this.pageNumber = 1;
        this.getDeeds(this.pageNumber);
      }
      if (filter === 'type' || filter.type === 'type') {
        this.selectedType = null;
        this.showTypesFilter = false;
        this.addQueryForCategories('/search', 'service_type', null, true);
        this.pageNumber = 1;
        this.getDeeds(this.pageNumber);
      }
      if (filter === 'cat' || filter.type === 'cat') {
        this.selectedCategorySlug = null;
        this.showCategoryFilter = false;
        this.addQueryForCategories('/search', 'slug', null, true);
        this.pageNumber = 1;
        this.getDeeds(this.pageNumber);
      }
      if(filter.type === 'option') {
        if(filter.id) {
          let tempOptions = [];
          this.serviceIncludes.forEach(item => {
            if(item.id === filter.id){
              item.selected = false
            } else if(item.selected) {
              tempOptions.push(item.id)
            }
          })
          let kOptions = tempOptions.sort();
          let pOptions = this.selectedOptions;
          this.selectedOptions = kOptions;
          if(JSON.stringify(pOptions) !== JSON.stringify(kOptions)){
            this.addQueryForCategories('/search', 'service_options', kOptions.length ? kOptions : null, true);
            this.pageNumber = 1;
            this.getDeeds(this.pageNumber);
          }
        }

        this.showOptionsFilter = false;
      }
      if (filter.type === 'metaOption') {
        if (filter.id) {
          let tempOptions = [];
          this.serviceMetaOptions.forEach(metaOption => {
            metaOption.options.forEach(option => {
              if(option.id === filter.id){
                option.selected = false;
              } else if(option.selected) {
                tempOptions.push(option.id)
              }
            });
          })
          let kOptions = tempOptions.sort();
          let pOptions = this.selectedMetaOptions;
          this.selectedMetaOptions = kOptions;
          if(JSON.stringify(pOptions) !== JSON.stringify(kOptions)){
            this.addQueryForCategories('/search', 'meta_options', kOptions.length ? kOptions : null, true);
            this.pageNumber = 1;
            this.getDeeds(this.pageNumber)
            this.reRender = false;
            this.reRender = true;
          }
        }

        this.showOptionsFilter = false;
      }
      if (filter === 'option') {
        this.selectedOptions = [];
        this.selectedMetaOptions = [];

        this.serviceIncludes.forEach(service => {
          service.selected = false;
        })

        this.serviceMetaOptions.forEach(metaOption => {
          metaOption.options.forEach(option => {
            option.selected = false
          })
        })


        let q_init = this.route.query;
        let qqq = this.addQueryForCategories('/search', 'service_options', null, true, true, q_init);
        this.addQueryForCategories('/search', 'meta_options', null, false, false, qqq);
        this.pageNumber = 1;
        this.getDeeds(this.pageNumber)

        this.showOptionsFilter = false;
      }
    },
    changeDeliveryFilter(day) {
      this.selectedDelivery = day;
      this.addQueryForCategories('/search', 'delivery', day, true);
      this.showDeliveryFilter = false;
      this.pageNumber = 1;
      this.getDeeds(this.pageNumber);
    },
    handleFocusOut(what='delivery') {
      if (what === 'delivery') {
        this.showDeliveryFilter = false
      }
      if (what === 'budget') {
        this.addBudgetFilter()
        this.maxBudgetModel = null
        this.minBudgetModel = null
      }
      if (what === 'types') {
        this.showTypesFilter = false
      }
      if (what === 'options') {
        this.addOptionFilter()
        this.showOptionsFilter = false
      }
      if (what === 'cats') {
        this.showCategoryFilter = false
      }
    },
    showFilterBox(what = 'delivery') {
      if(this.pageLoaded){
        if(what === 'delivery'){
          this.showDeliveryFilter = true;
          this.showBudgetFilter = false;
          this.$nextTick(() => {
            document.getElementById("delivery-filter-box").focus()
          })
        }

        if(what === 'budget'){
          this.minBudgetModel = isNaN(this.minBudget) ? null : this.minBudget;
          this.maxBudgetModel = isNaN(this.maxBudget) ? null : this.maxBudget;
          this.showDeliveryFilter = false;
          this.showBudgetFilter = true;
        }

        if(what === 'options'){
          this.showOptionsFilter = true;
        }

        if(what === 'types'){
          this.showTypesFilter = true;
        }

        if(what === 'cats'){
          this.showCategoryFilter = true;
        }

      }
    },
    onPageClick() {
      this.categorySelect.forEach(category => {
        category.hover = false
      });
    },
    prepareHtmlTitleAndDescription(term) {
      if (this.selectedCategorySlug) {
        this.$store.state.indexHTMLTitle = `${term} ${this.selectedCategorySlug} içinde İş İlanları | Bionluk`;
        this.$store.state.indexHTMLMetaDescription = `${term} ${this.selectedCategorySlug} içinde İş İlanları | Bionluk`;
      } else {
        this.$store.state.indexHTMLTitle = `İş İlanları | Bionluk içinde ${term}`;
        this.$store.state.indexHTMLMetaDescription = `İş İlanları | Bionluk içinde ${term}`;
      }
    },
    async prepareCategorySelect(category) {
      let categorySelect = [];
      if (!category.parent_id) {
        const categories = await this.$store.state.categoriesPromise
        const foundChildCategories = categories.filter(({ parent_id }) => parent_id === category.id)

        categorySelect.push({
          name: category.name,
          selected: true,
          slug: category.slug,
          parentId: category.parent_id,
          count: category.count,
          imageInfo:category.sub_cat_info,
          gigCount: category.gig_count,
          userCount: category.user_count,
          hover:false,
        });

        foundChildCategories.forEach(childCategory => {
          categorySelect.push({
            name: childCategory.name,
            selected: false,
            slug: childCategory.slug,
            parentId: childCategory.parent_id,
            count: childCategory.count,
            imageInfo:childCategory.sub_cat_info,
            gigCount: childCategory.gig_count,
            userCount: childCategory.user_count,
            hover:false,
          });
        })
      } else {
        const categories = await this.$store.state.categoriesPromise
        const foundChildCategories = categories.filter(({ parent_id }) => parent_id === category.parent_id)
        let foundParentCategory = await this.findCategoryByID(category.parent_id)

        categorySelect.push({
          name: foundParentCategory.name,
          selected: false,
          slug: foundParentCategory.slug,
          parentId: foundParentCategory.parent_id,
          count: foundParentCategory.count,
          imageInfo:foundParentCategory.sub_cat_info,
          gigCount: foundParentCategory.gig_count,
          userCount: foundParentCategory.user_count,
          hover:false,
        });

        foundChildCategories.forEach(childCategory => {
          categorySelect.push({
            name: childCategory.name,
            selected: childCategory.id === category.id,
            slug: childCategory.slug,
            parentId: childCategory.parent_id,
            count: childCategory.count,
            imageInfo:childCategory.sub_cat_info,
            gigCount: childCategory.gig_count,
            userCount: childCategory.user_count,
            hover:false
          });
        })
      }

      this.categorySelect = categorySelect;
    },
    getMetaOptionCounts() {
      let selectedMetaOptions = [];
      this.serviceMetaOptions.forEach(metaOption =>{
        metaOption.options.forEach(option => {
          if (option.selected) {
            const foundIndex = selectedMetaOptions.findIndex(selectedMetaOption => selectedMetaOption.id === metaOption.id)
            if (foundIndex > -1){
              selectedMetaOptions[foundIndex].options.push(option.id)
            } else {
              selectedMetaOptions.push({
                id: metaOption.id,
                options: [option.id]
              })
            }
          }
        });
      });

      let postObj = {
        onlineOnly:this.showOnlineOnly,
        slug: this.selectedCategorySlug,
        duration:this.selectedDelivery,
        minBudget: this.minBudget,
        maxBudget: this.maxBudget,
        service_type_id: this.selectedType,
        limit: 1,
        count: true,
        facets: ["meta_options,count:999"],
        meta_options: selectedMetaOptions,
        service_includes: this.selectedOptions,
        scoringProfile:'term_search',
        term:this.term
      }

      this.serviceMetaOptions.forEach(metaOption => {
        postObj['excluded_meta_id'] = metaOption.id;
        this.searchDeeds2(postObj)
          .then(result => {
            metaOption.options.forEach(option => {
              option.count = 0;
              result.facets.meta_options.forEach(item => {
                if (item.value === option.id) {
                  option.count = item.count;
                }
              })
            });

            metaOption.options = sortBy(metaOption.options, "count").reverse();
          })
      })
    },
    getServiceIncludesCounts() {
      let selectedMetaOptions = [];
      this.serviceMetaOptions.forEach(metaOption =>{
        metaOption.options.forEach(option => {
          if(option.selected){
            const foundIndex = selectedMetaOptions.findIndex(selectedMetaOption => selectedMetaOption.id === metaOption.id)
            if(foundIndex > -1){
              selectedMetaOptions[foundIndex].options.push(option.id)
            } else {
              selectedMetaOptions.push({
                id:metaOption.id,
                options:[option.id]
              })
            }
          }
        });
      });
      let postObj = {
        onlineOnly:this.showOnlineOnly,
        slug: this.selectedCategorySlug,
        duration:this.selectedDelivery,
        minBudget: this.minBudget,
        maxBudget: this.maxBudget,
        service_type_id: this.selectedType,
        limit: 1,
        count: true,
        facets: ["service_includes_array,count:999"],
        meta_options:selectedMetaOptions,
        scoringProfile:'term_search',
        term:this.term
      }
      this.searchDeeds2(postObj)
        .then(result => {
          let temp = [...this.serviceIncludes];
          temp.forEach(service => {
            service.count = 0;

            result.facets.service_includes_array.forEach(item => {
              if (item.value === service.id) {
                service.count = item.count;
              }
            })
          })
          this.serviceIncludes = sortBy(temp, "count").reverse();

        })
    },
    getServiceTypeCounts() {
      let selectedMetaOptions = [];
      this.serviceMetaOptions.forEach(metaOption =>{
        metaOption.options.forEach(option => {
          if(option.selected){
            const foundIndex = selectedMetaOptions.findIndex(selectedMetaOption => selectedMetaOption.id === metaOption.id)
            if(foundIndex > -1){
              selectedMetaOptions[foundIndex].options.push(option.id)
            } else {
              selectedMetaOptions.push({
                id:metaOption.id,
                options:[option.id]
              })
            }
          }
        });
      });
      let postObj = {
        onlineOnly:this.showOnlineOnly,
        slug: this.selectedCategorySlug,
        duration:this.selectedDelivery,
        minBudget: this.minBudget,
        maxBudget: this.maxBudget,
        service_includes: this.selectedOptions,
        limit: 1,
        count: true,
        facets: ["service_type_id,count:999"],
        meta_options: selectedMetaOptions,
        scoringProfile:'term_search',
        term:this.term
      }
      this.searchDeeds2(postObj)
        .then(result => {
          let gigCount = 0;
          let temp = [...this.categoryTypes];
          temp.forEach(service_type => {
            service_type.count = 0;
            result.facets.service_type_id.forEach(item => {
              if (item.value === service_type.id) {
                gigCount += item.count;
                service_type.count = item.count;
              }
            })
          })
          this.categoryTypes = sortBy(temp, "count").reverse();
          this.gigsCount = gigCount;
        })
    },
    getCategoryCounts() {
      let postObj = {
        onlineOnly:this.showOnlineOnly,
        duration:this.selectedDelivery,
        minBudget: this.minBudget,
        maxBudget: this.maxBudget,
        limit: 1,
        count: true,
        facets: ["subCategorySlug,count:999"],
        scoringProfile:'term_search',
        term:this.term
      }
      this.searchDeeds2(postObj)
        .then(async result => {
          let gigCount = 0;
          let temp = [];
          for (const item of result.facets.subCategorySlug) {
            let fCategory = await this.findCategoryBySlug(item.value)
            temp.push({
              count:item.count,
              slug: item.value,
              id: fCategory.id,
              name: fCategory.name
            })
            gigCount += item.count;
          }
          this.facetCategories = sortBy(temp, "count").reverse().slice(0,8);
          this.gigsCount = gigCount;
        })
    },
    getDeeds(pageNum, isFeatured = false, calledFrom = '') {
      this.pageLoaded = false;

      let selectedMetaOptions = []
      this.serviceMetaOptions.forEach(metaOption => {
        metaOption.options.forEach(option => {
          if (option.selected) {
            const foundIndex = selectedMetaOptions.findIndex(selectedMetaOption => selectedMetaOption.id === metaOption.id)
            if(foundIndex > -1){
              selectedMetaOptions[foundIndex].options.push(option.id)
            } else {
              selectedMetaOptions.push({
                id:metaOption.id,
                options:[option.id]
              })
            }
          }
        });
      })

      this.searchDeeds2({
        onlineOnly:this.showOnlineOnly,
        term:this.term,
        slug: this.selectedCategorySlug,
        limit: this.limit,
        count: true,
        offset: (pageNum - 1) * this.limit,
        orderBy: Number(this.orderBy),
        isReallyFeatured: isFeatured,
        scoringProfile:'term_search',
        duration: this.selectedDelivery,
        minBudget: this.minBudget,
        maxBudget: this.maxBudget,
        service_type_id: this.selectedType,
        service_includes: this.selectedOptions,
        meta_options:selectedMetaOptions,
      })
        .then(async result => {

          if (result.count) {
            this.totalCount = (Math.ceil(result.count / this.limit));
          }
          if (result.results.length === 0) {
            this.totalCount = 0;
          }
          this.allGigsCount = result.count;

          let trackProps = {}
          let imp_cat_id = null;
          let imp_sub_cat_id = null;
          if (this.selectedCategorySlug) {
            let foundCategory = await this.findCategoryBySlug(this.selectedCategorySlug);
            let foundParentCategory = await this.findCategoryByID(foundCategory.parent_id);
            if (!foundCategory.parent_id) {
              imp_cat_id = foundCategory.id;
              trackProps.categoryId = foundCategory.id;
              trackProps.parentCategoryId = null;
            } else {
              imp_cat_id = foundParentCategory.id;
              imp_sub_cat_id = foundCategory.id;
              trackProps.categoryId = foundCategory.id;
              trackProps.parentCategoryId = foundParentCategory.id;
            }
          }


          this.impressionUUID = this.Helper.generateUUID();
          let trackGigObj = [];
          result.results.forEach((gig, gigdex) => {
            trackGigObj.push({
              gig_id:gig.id,
              seller_id:gig.seller_id,
              order_id: gigdex + 1
            })
          });
          if(trackGigObj.length > 0){
            let trackingProps = {
              page:'search',
              term:this.route.query.term,
              placement: this.showOnlineOnly ? 'online' :  Number(this.orderBy) === 1 ? 'default' : Number(this.orderBy) === 2 ? 'sort_bestseller' : 'sort_new',
              info: trackGigObj,
              cat_id: imp_cat_id || '',
              sub_cat_id: imp_sub_cat_id || '',
              selectedTypes: this.selectedType || null,
              title: this.impressionUUID
            };
            this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
          }

          if ((pageNum - 1) * this.limit === 0) {
            trackProps.selectedTypes = this.selectedType;
            this.Helper.trackEvents.pageView(this.Helper.searchPageProps(result.count, this.route.query, trackProps));
          }
          if(this.pageNumber === 1 && calledFrom !== 'service_type') {
            this.getServiceTypeCounts();
          }

          if (this.pageNumber === 1 && calledFrom !== 'category') {
            this.getCategoryCounts();
          }

          this.getServiceIncludesCounts()
          this.getMetaOptionCounts()

          this.pageLoaded = true;


          result.results.forEach(gig => {
            gig.portfolios = JSON.parse(gig.portfolios)
            gig.likeStatus = {
              action: 1,
              actionIcon: "icon/tansel_bionluk_like_bos_s.svg",
              actionText: "Beğen"
            }
          })
          this.gigs = [...result.results];

          this.api.general.getGigLikesV2(this.gigs.map(gig => gig.id), this.selectedCategorySlug)
            .then(({data}) => {
              let likeResult = data;
              this.biaInfo = likeResult.data.bia_info;

              this.$store.state.biaOnlineCount = this.biaInfo.online_users;
              this.$store.state.biaOnlineText = this.biaInfo.online_text;


              if (this.mounted) {
                this.gigs.forEach(gig => {
                  if (this.mounted) {
                    let foundLike = likeResult.data.likeStatuses.find(({ id }) => id === gig.id)
                    if(foundLike){
                      gig.likeStatus = foundLike.likeStatus;
                      this.gigs = [...result.results];
                    }
                  }
                })
              }
            })
            .catch(err => {
              console.log(err);
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        })
    },
    orderChange(e) {
      this.$store.state.clickedFrom = 'orderby dropdown'
      const newVal = e.target.value;
      this.addQueryForCategories('/search', 'order_by', newVal, true);
      this.pageNumber = 1;
      this.getDeeds(this.pageNumber);
    },
    clickPagination(pageNum) {
      this.addQueryForCategories('/search', 'page', pageNum === 1 ? null : pageNum);
      this.getDeeds(pageNum);
    },
    addQueryForCategories(initialUrl, queryKey, queryValue, resetPage = false, returnOnly = false, init_query = null) {
      let query = init_query ? init_query : this.route.query;
      if(returnOnly){
        let queryOnly = {};
        if (!isEmpty(query)) {
          for (const key in query) {
            if (key === queryKey) {
              if(queryValue && (!Array.isArray(queryValue) || queryValue.length)){
                queryOnly[queryKey] = queryValue;
              }
            } else {
              if (query[key]){
                if(!(key === 'page' && resetPage)){
                  queryOnly[key] = query[key];
                }

              }
            }
          }
        }

        if(queryValue && (!Array.isArray(queryValue) || queryValue.length)){
          queryOnly[queryKey] = queryValue;
        }


        return queryOnly;
      } else {

        let urlStr = initialUrl + '?';
        if (!isEmpty(query)){
          for (const key in query) {
            if (key === queryKey) {
              if (queryValue && (!Array.isArray(queryValue) || queryValue.length)) {
                queryValue = decodeURIComponent(queryValue);
                urlStr += '&' + queryKey + '=' + encodeURIComponent(queryValue);
              }

            } else {
              if (!(key === 'page' && resetPage)){
                query[key] = decodeURIComponent(query[key]);
                urlStr += '&' + key + '=' + encodeURIComponent(query[key]);
              }
            }
          }
        }


        if (!urlStr.includes(queryKey) && queryValue && (!Array.isArray(queryValue) || queryValue.length)) {
          queryValue = decodeURIComponent(queryValue);
          urlStr += '&' + queryKey + '=' + encodeURIComponent(queryValue);
        }


        this.$router.push(encodeURI(urlStr));
      }
    },
    resetAllSettings(action = '') {
      this.orderBy = 1;
      this.offset = 0;
      this.pageNumber = 1;
      this.selectedDelivery = null;
      this.selectedType = null;
      this.selectedOptions = [];
      this.selectedMetaOptions = [];
      this.minBudget = null;
      this.maxBudget = null;
      this.minBudgetModel = null;
      this.maxBudgetModel = null;
      this.allGigsCount = 0;
      this.optionsSliceCount = 6;

      this.showTypesFilter = false;
      this.showOptionsFilter = false;
      this.showBudgetFilter = false;
      this.showDeliveryFilter = false;

      if(action !== 'getDeeds'){
        this.categoryTypes = [];
        this.serviceIncludes = [];
        this.serviceMetaOptions = [];
        this.facetCategories = [];
      } else {
        this.serviceIncludes.forEach(service => {
          service.selected = false;
        })
        this.serviceMetaOptions.forEach(metaOption => {
          metaOption.sliceCount = 4;
          metaOption.options.forEach((mo,mi) => {
            mo.selected = false;
            mo.count = 0;
          })
        });
        this.$router.push('/search?term='+this.term);
        this.getDeeds(this.pageNumber);
      }
    },
    async applyChanges(newValue) {
      this.resetAllSettings()
      this.selectedCategorySlug = (this.route.query.slug && this.route.query.slug !== 'undefined' && this.route.query.slug !== "null") ? this.route.query.slug : null;
      this.selectedDelivery = (this.route.query.delivery && this.route.query.delivery !== 'undefined') ? Number(this.route.query.delivery) : null;
      this.minBudget = (this.route.query.minBudget && this.route.query.minBudget !== 'undefined') ? Number(this.route.query.minBudget) : null;
      this.maxBudget = (this.route.query.maxBudget && this.route.query.maxBudget !== 'undefined') ? Number(this.route.query.maxBudget) : null;

      this.orderBy = this.Helper.isIndexerBotOrDevelopmentEnv() ? 3 : (this.route.query.order_by && this.route.query.order_by !== 'undefined') ? Number(this.route.query.order_by) : 1;

      this.serviceIncludes = [];
      this.serviceMetaOptions = [];

      this.showOnlineOnly = (this.route.query.online && this.route.query.online !== 'undefined' && Number(this.route.query.online)) === 1;



      this.prepareHtmlTitleAndDescription(newValue);


      let selectedCategorySlug = this.selectedCategorySlug
      const category = await this.findCategoryBySlug(selectedCategorySlug)
      if(selectedCategorySlug && category){
        this.selectedCategory = category;
        this.categoryId = category.id;
        this.prepareCategorySelect(category);

        if(this.selectedCategory.parent_id){
          this.categoryTypes = category.category_service_types;
          this.serviceIncludes = category.service_includes;
          this.serviceIncludes.forEach(service => {
            service.selected = false;
          });

          this.serviceMetaOptions = category.meta_options;
          this.serviceMetaOptions.forEach(metaOption => {
            metaOption.sliceCount = 4;
            metaOption.options.forEach((mo,mi) => {
              mo.selected = false;
              mo.count = 0;
            })
          });

          this.pageNumber = (this.route.query.page && this.route.query.page !== 'undefined') ? Number(this.route.query.page) : 1;


          let selectedType = (this.route.query.service_type && this.route.query.service_type !== 'undefined') ? Number(this.route.query.service_type) : null;
          if (selectedType && this.categoryTypes.length > 0) {
            let foundIndex = this.selectedCategory.category_service_types.findIndex(serviceType => serviceType.id === parseInt(selectedType))
            if(foundIndex > -1){
              this.selectedType = selectedType;
            }
          }


          let selectedOptions = (this.route.query.service_options && this.route.query.service_options !== 'undefined') ? this.route.query.service_options : null;
          let selectedMetaOptions = (this.route.query.meta_options && this.route.query.meta_options !== 'undefined') ? this.route.query.meta_options : null;

          if(selectedOptions || selectedMetaOptions){
            if(selectedOptions){
              selectedOptions = decodeURIComponent(selectedOptions)
              let arr = selectedOptions.split(',');
              if(arr && arr.length > 0){
                this.serviceIncludes.forEach(service => {
                  arr.forEach(item => {
                    if(parseInt(item) === service.id){
                      service.selected = true;
                    }
                  })
                })
              }
            }


            if(selectedMetaOptions){
              selectedMetaOptions = decodeURIComponent(selectedMetaOptions)
              let arrMeta = selectedMetaOptions.split(',');
              if(arrMeta && arrMeta.length > 0){
                this.serviceMetaOptions.forEach(metaOption => {
                  metaOption.options.forEach(option=> {
                    arrMeta.forEach(item => {
                      if(parseInt(item) === option.id){
                        option.selected = true;
                      }
                    })
                  });
                })
              }
            }

            this.addOptionFilter();
          }

        }

      }

      this.getDeeds(this.pageNumber);

    }
  },
  computed: {
    computedSelectedAnyOptions() {
      let selectedCount = this.selectedOptions && this.selectedOptions.length > 0 ? 1 : 0;

      if(selectedCount === 0){
        this.serviceMetaOptions.forEach(metaOption => {
          metaOption.options.forEach((mo,mi) => {
            if(mo.selected){
              selectedCount++
            }
          })
        });
      }
      return selectedCount > 0;
    },
    async filterLabels() {
      let arr = []
      if (this.selectedType) {
        arr.push({type:'type', title: this.categoryTypes.find(({ id }) => id === this.selectedType).name})
      }
      if (this.selectedCategorySlug) {
        arr.push({type:'cat', title: await this.findCategoryBySlug(this.selectedCategorySlug).name})
      }
      if(this.selectedOptions.length > 0){
        this.serviceIncludes.forEach(service => {
          let index = this.selectedOptions.indexOf(service.id);
          if (service.selected && index > -1) {
            arr.push({type:'option', title: service.title, id:service.id})
          }
        })
      }
      this.serviceMetaOptions.forEach(metaOption => {
        metaOption.options.forEach(option => {
          if (option.selected) {
            arr.push({ type:'metaOption', title: option.title_for_buyer, id: option.id })
          }
        })

      })
      if(this.selectedDelivery){
        arr.push({ type:'delivery', title: this.deliveryLabels.find(({ id }) => id === this.selectedDelivery).title })
      }
      if(this.minBudget || this.maxBudget){
        let budgetTitle = '';
        if(this.minBudget && this.maxBudget){
          budgetTitle = this.format_tl(this.minBudget) + 'TL - ' + this.format_tl(this.maxBudget) + 'TL';
        } else if(this.minBudget){
          budgetTitle = this.format_tl(this.minBudget) + 'TL Üstü';
        } else if(this.maxBudget){
          budgetTitle = this.format_tl(this.maxBudget) + 'TL Altı';
        }
        arr.push({ type: 'budget', title: budgetTitle })
      }

      return arr;

    },
    computedOptions() {
      return this.serviceIncludes.filter(({ count }) => count > 0)
    },
    computedAllFilters() {
      return {
        duration: this.selectedDelivery,
        minBudget: this.minBudget,
        maxBudget: this.maxBudget,
        service_type_id: this.selectedType,
        service_includes: this.selectedOptions
      }
    },
    showAllServiceOptions() {
      return this.serviceIncludes?.length > 0 || this.serviceMetaOptions?.length > 0
    }
  },
  watch: {
    showOnlineOnly(newVal, oldVal) {
      if(oldVal === false && newVal === true){
        this.addQueryForCategories('/search', 'online', 1, true);
        this.getDeeds();
      }

      if(newVal === false && oldVal === true){
        if(this.route.query.online){
          this.addQueryForCategories('/search', 'online', null, true);
          this.getDeeds();
        }
      }
    },
    'route.query': function (newVal, oldVal) {
      let term = decodeURIComponent((newVal.term && newVal.term !== 'undefined') ? newVal.term : '');
      let oldTerm = decodeURIComponent((oldVal.term && oldVal.term !== 'undefined') ? oldVal.term : '');

      let selectedCategorySlug = (newVal && newVal.slug && newVal.slug !== 'undefined' && newVal.slug !== "null") ? newVal.slug : null;
      let oldSelectedCategorySlug = (oldVal && oldVal.slug && oldVal.slug !== 'undefined' && oldVal.slug !== "null") ? oldVal.slug : null;


      if(term !== oldTerm){
        this.term = term;
        this.prepareHtmlTitleAndDescription(this.term);
        this.applyChanges(this.term);
      } else if(selectedCategorySlug !== oldSelectedCategorySlug){
        this.term = term;
        this.prepareHtmlTitleAndDescription(this.term);
        this.applyChanges(this.term);
      }
    },
    showOptionsFilter(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          let ele = document.getElementsByClassName("long-options")[0]
          let rect = ele.getBoundingClientRect()
          ele.style.maxHeight = (window.innerHeight - rect.top - 125) + "px"
        })
      }
    }
  },
  beforeDestroy() {
    this.mounted = false;
  },
  created() {
    this.mounted = true;
    let term = (this.route.query.term && this.route.query.term !== 'undefined' && this.route.query.term !== "null") ? this.route.query.term : null;
    this.term = decodeURIComponent(term);
    this.applyChanges(this.term);
    let showHelpB = window.localStorage.getItem("show_search_help_buble");
    if(showHelpB && showHelpB === 'no'){
      this.showHelpBubble = false;
    } else {
      this.showHelpBubble = true;
      if(!showHelpB){
        window.localStorage.setItem("show_search_help_buble", 'yes');
      }
    }
  }
}

</script>

<style scoped lang="scss">

.helpbubble {

  z-index: 99999999;
  position: absolute;
  background: #00a575;
  color: #FFFFFF;
  top:32px;
  margin-left: 30px;
  width: 250px;
  height: 130px;
  border-radius: 10px;
  padding: 0px;
  .help-title{
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    .new{
      margin-left: 5px;
      padding:4px 10px 5px;
      border-radius: 5px;
      background: white;
      font-size: 12px;
      color: #00a575;

    }
  }

  .gotit{
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}
.helpbubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #00a575 transparent;
  border-width: 0 12px 12px;
  top: -12px;
  left: 12%;
  margin-left: -12px;
}

.go-request{
  background: url("https://gcdn.bionluk.com/site/general/sl_bg%402x.png");
  background-size: cover;
  width: 100%;
  height: 224px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left{
    margin-left: 60px;
    .title{
      width: 520px;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.67;
      color: #ffffff;
      span{
        font-weight: bold;
      }
    }
    .cuper-red-button{
      width: 200px;
      height: 44px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
    }

  }

  .right{
    margin-right: 60px;
    width: 335px;
    height: 203px;
  }

}

.filter-labels{
  display: flex;
  width: 1190px;
  flex-wrap: wrap;
  margin-bottom: 15px;
  .filter-label{
    margin-right: 16px;
    padding-left: 15px;
    padding-right: 15px;
    height: 37px;
    border-radius: 5px;
    background-color: #2d3640;
    color: white;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    margin-bottom: 10px;

    .close-icon-container{
      &:hover{
        opacity: 0.9;
      }
      cursor: pointer;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      .close-icon /deep/ {
        width: 10px;
        height: 10px;
        path {
          fill: #00a575;
        }
      }
    }
  }
}

#facet-cats-filter-box{
  width: 330px;
}

#option-filter-box{
  width: 560px
}
#types-filter-box{
  width: 330px;
}
#budget-filter-box{
  width: 330px;
}

#delivery-filter-box{
  width: 290px;
}
.filter-options{
  margin-top: 5px;
  outline: 0;
  position: absolute;


  object-fit: contain;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(45, 54, 64, 0.15);

  background-color: #ffffff;
  z-index: 99;
  border: solid 1px rgba(75, 79, 82, 0.2);


  .long-options{
    max-height: 100px;
    padding-top: 20px;
    padding-bottom: 40px;
    overflow-y: scroll;
  }

  .filter-options-container{
    margin: 10px 24px 0;

    .filter-options-container-title{
      color: #2D3640; font-weight: 600; font-size: 18px;
    }
    .show-more{
      margin-top: -10px;
      color: #00a575;
      font-weight: 600;
      font-size: 15px;
      cursor: pointer;
      &:hover{
        opacity: 0.9;
      }
    }

    .filter-radio-item{
      display: flex; align-items: center; cursor: pointer;
      margin-bottom: 20px;

      .filter-radio-empty{
        width: 17px;
        height: 17px;
        border-radius: 50%;
        border: solid 1px #8b95a1;
      }

      .filter-radio-label{
        font-size: 16px;  color: #2d3640; margin-left: 8px; line-height: 1.4
      }

      .filter-radio-label-active{
        font-size: 16px; color: #2d3640; font-weight: 500; margin-left: 8px; line-height: 1.4
      }

      .filter-radio-full{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: solid 4px #00a575;
      }


    }

    .filter-checkbox-item{
      cursor: pointer;
      display: flex;
      width: 50%;
      margin-bottom: 20px;

      .filter-checkbox-empty{
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: solid 1px #8b95a1;
      }

      .filter-checkbox-full{
        width: 12px;
        height: 12px;
        border-radius: 5px;
        border: solid 4px #00a575;

      }

      .filter-checkbox-label{
        padding-left: 9px;
        font-size: 15px;
        opacity: 0.85;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #2D3640;

      }

      .filter-checkbox-label-active{
        padding-left: 9px;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #2d3640;

      }

    }

    .filter-category-item{
      font-size: 15px;
      font-weight: 500;
      color: #2D3640;
      display: flex; align-items: center; cursor: pointer;
      margin-bottom: 20px;
      justify-content: space-between;
      line-height: 19px;

      &.selected{
        font-weight: 600;
        color: #39d1a2;
      }
      &:hover{
        color: #39d1a2;
      }

      .cunts{
        margin-left: 10px;
        color: #b8bdc5;
      }

    }

    .temizle{
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      color: #2d3640;
      &:hover{
        opacity: 0.8;
      }
    }

    .uygula{
      cursor: pointer;
      border-radius: 2px;
      border: none;
      background-color: #2d3640;
      padding: 3px auto 5px;
      color: #ffffff;
      letter-spacing: -0.3px; font-weight: 600; font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      height: 28px;
    }
  }
}

.filter-box{
  position: relative;
  margin-right: 16px;
  .the-box{
    cursor: pointer;
    display:flex;
    border-radius: 5px;
    padding: 9px 20px 10px;
    font-size: 17px;
    font-weight: 600;

    color:#2D3640;
    border: 1px solid #bfc8d2;
    .icon-container{

      margin-left: 15px;

      .icon /deep/ {
        width: 10px;
        height: 10px;
        path {
          fill: #5e6b79;
        }
      }
    }
    &.active{
      color: #00a575;
      font-weight: 500;
      background: rgba(0, 165, 117,0.2);
      border: solid 1px #00a575!important;
      .icon-container{

        margin-left: 15px;

        .icon /deep/ {
          width: 10px;
          height: 10px;
          path {
            fill: rgba(0, 165, 117,1);
          }
        }
      }
    }


  }


}

.category-title {
  background-color: #fff;
  min-width: 1193px;
  display: flex;
  width: 1193px;
  margin: 0 auto;
  text-align: left;
  padding-top: 10px;
  position: relative;

}

.category-sub-title {
  background-color: #fff;
  display: flex;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  padding-top: 10px;
  position: relative;
  height: 141px;

  .category-sub-title-inside{
    max-width: 1193px;
    width: 1193px;
    margin: 0 auto;
    text-align: left;
    display: flex;

    .cat-title{
      font-size: 34px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 10px;

    }

    .cat-description{
      font-size: 16px;
      line-height: 1.5;
      color: #ffffff;
    }
  }

}

.super-drop-tek-cizgi-cats {
  background: url(https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg) no-repeat right;

  font-family: 'sofia-pro';
  position: relative;
  color: #2d3640;
  border: none;
  cursor: pointer;

  margin-left: 5px;
  background:transparent;
  font-weight: 600;
  font-size: 16px;
}

.super-drop-tek-cizgi:hover {
  border: none;
  border-bottom: solid 1px #2d3640;
  color: #2d3640;
  cursor: pointer;
}

input.super-drop-tek-cizgi:focus {
  border: none;
  border-bottom: solid 1px #2d3640;
  color: #2d3640;
  cursor: pointer;
}

.catFilter {
  height: 70px;
  background-color: white;
  //border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  justify-content: center;
  top: 105px;
}

.catFilterFixed {
  position: fixed;
  margin-top: -225px;
}

.gigContainer {
  display: flex;
  padding-bottom: 10px;
  width: 1193px;
  margin: 0 auto;
  padding-top: 30px;
}

.gig-list-container {

  position: relative;
  display: flex;
  width: 1193px;
  flex-wrap: wrap;
  margin-top: 30px;


  .gig-list-gig{
    margin-bottom: 40px;
    margin-left: 24px;
    &:nth-child(4n+1) {
      margin-left: 0;
    }
  }

  &:hover {
    .swiper-button-prev, .swiper-button-next {
      visibility: visible;
    }
  }
}

.swiper-button-prev, .swiper-button-next {
  visibility: hidden;

}

.catFilter-container {
  min-width: 1193px;
  display: flex;
  justify-content: space-between;
}

.select-type-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;


}

.card-container {
  width: 278px;
  height: 298px;
  background-color: #ffffff;
  border: solid 1px #dfe4ec;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;

  &:nth-child(4n+1) {
    margin-left: 0;
  }
}

.card-image-container {
  height: 148px;
  background-color: #fafbfc;
  border-bottom: solid 1px #dfe4ec;
}

.card-detail-container {
  padding: 15px;
}

.card-detail-header-large {
  width: 234px;
  height: 16px;
  background-color: #e1e6ed;
  margin-bottom: 4px;
}

.card-detail-header-small {
  width: 117px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-image {
  width: 28px;
  height: 28px;
  background-color: #dfe4ec;
  border-radius: 50%;
  margin-right: 8px;
}

.card-detail-user-name {
  width: 50px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-currency {
  width: 63px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 15px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe4ec;
}

.card-detail-user {
  display: flex;
  align-items: center;
}

.card-like-icon {
  width: 16px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-currency-icon {
  width: 42px;
  height: 20px;
  background-color: #bdc5d0;
}

.card-like-currency {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 15px;
}

.card {
  animation-name: loading;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes loading {
  10% {
    background-color: #dfe4ec;
  }
  2% {
    background-color: #fafbfc;
  }
}
</style>
